/* eslint-disable react-hooks/exhaustive-deps */
import { Dashboard, DashboardType } from '@eagle/core-data-types';
import { Breadcrumbs, DetailPage, FeatureIcons, MiddleSpinner, PortalFeatureIcons, useAuthenticated, useCustomRoutes, useDynamicModule } from '@eagle/react-common';
import { Box, Link, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router-dom';
import { DashboardDetailCard } from './dashboard-detail-card';

interface Props {
  pageType: DashboardType;
}

export const DASHBOARD_DETAIL_FLAG = 'track-dashboard-detail-feature';

export const DashboardDetail: FC<Props> = ({ pageType: initialPageType }) => {
  const { t } = useTranslation(['track']);
  const { axios } = useAuthenticated();
  const { dashboardId } = useParams();
  const { dashboards, reports } = useCustomRoutes();
  const dashboardsHref = useHref(`/${dashboards}`);
  const reportsHref = useHref(`/${reports}`);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const [pageType, setPageType] = useState(initialPageType);

  const renderPageContent = (data: Dashboard): JSX.Element => <>
    <Stack
      direction="column"
      flex={[2, 2]}
      spacing={2}
      sx={{ minWidth: 0 }}
    >
      <DashboardDetailCard data={data} setPageType={setPageType} />
    </Stack>

    <Stack
      direction="column"
      flex={[3, 3]}
      spacing={2}
    />
  </>;

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={pageType === DashboardType.DASHBOARD ? dashboardsHref : reportsHref}
        underline="hover"
      >
        {t(`track:page.dashboard-detail.${pageType}.sub-title`)}
      </Link>
      <Typography color="text.primary" data-testid="person-detail-subtitle-breadcrumb">{t('track:page.dashboard-detail.dashboard-details.sub-title')}</Typography>
    </Breadcrumbs>
  );

  const loadData = useCallback(
    async () => {
      if (!dashboardId) return;
      const result = await axios.get<Dashboard>(`/api/v1/dashboard/${dashboardId}`);
      return result.data;
    },
    [dashboardId]
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <Box sx={{
      display: 'flex',
      flex: [1, 1],
      flexDirection: 'column',
      minWidth: 0,
    }}>
      <DetailPage<Dashboard>
        data-testid='dashboard-detail'
        actions={[]}
        breadcrumbs={breadcrumbs}
        loadData={loadData}
        pageIcon={module?.DashboardIcon && <module.DashboardIcon />}
        renderDisplay={({ display }) => display}
        renderPageContent={renderPageContent}
        renderPageTitle={(data: Dashboard) => data.display}
      />
    </Box>
  );
};
