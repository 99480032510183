import { captureException, captureMessage } from '@eagle/react-common';
import i18n from 'i18next';
import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { debounce } from 'lodash';
import { initReactI18next } from 'react-i18next';

// Check if the current environment is production
const isProduction = import.meta.env.PROD;

const processZhLanguage = (lang: string): string => {
  if (lang.startsWith('zh') && !lang.includes('Hant')) {
    return `zh-Hant${lang.substring(2)}`;
  }
  return lang;
};

const customDetector: CustomDetector = {
  name: 'customZhDetector',
  lookup: (_options) => {
    const detectedLang = window.navigator.language;
    return processZhLanguage(detectedLang);
  },
};

const localStorageDetector: CustomDetector = {
  name: 'localStorageDetector',
  lookup: (_options) => {
    const lang = localStorage.getItem('portal-language-preference');
    if (lang && lang !== 'null') {
      const cleanLang = lang.replace(/^"|"$/g, '').replace('_', '-'); // Remove quotes and replace underscore with hyphen
      return processZhLanguage(cleanLang);
    }
    return undefined;
  },
};

const reportMissingKey = debounce((namespace: string, key: string, fallbackValue: string | undefined): void => {
  captureMessage(`Missing translation key: ${namespace}:${key}`, {
    level: 'warning',
    tags: {
      namespace,
      fallbackValue,
    },
  });
}, 2000);

const enabledNamespaces = ['common', 'auth', 'admin', 'track', 'manage', 'terms', 'l10n'];

i18n
  // load translation using local backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `${import.meta.env.BASE_URL ?? ''}/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: {
        v: import.meta.env.VITE_BUILD ?? '',
      },
      allowMultiLoading: true,
      languageTransform: (lng: string) => {
        return processZhLanguage(lng);
      },
    },
    debug: false,
    detection: {
      order: ['localStorageDetector', 'customZhDetector', 'navigator', 'querystring', 'cookie', 'htmlTag'],
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    missingKeyHandler: (lngs, namespace, key, fallbackValue) => {
      if (isProduction && lngs.includes('en')) {
        reportMissingKey(namespace, key, fallbackValue);
      }
    },
    ns: enabledNamespaces,
    saveMissing: isProduction,
    preload: ['en'],
  })
  .catch(captureException);

// Add the custom detector
(i18n.services.languageDetector as LanguageDetector).addDetector(customDetector);
(i18n.services.languageDetector as LanguageDetector).addDetector(localStorageDetector);

export default i18n;
