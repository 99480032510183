/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { RoleFunction } from '@eagle/common';
import { DashboardType } from '@eagle/core-data-types';
import {
  AuthStatus,
  PROFILE_PAGE_CREATE_SUBSCRIPTIONS_FLAG,
  T_MANY,
  T_ONE,
  useAuth,
  useCustomRoutes,
  useFlags,
  useHasAuthorization
} from '@eagle/react-common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import {
  AlertDetailPage,
  AlertListPage,
  CameraContentPage,
  DashboardDetailPage,
  DashboardPage,
  DashboardsListPage,
  EventHistoryPage,
  OverviewPage,
  PersonDetailPage,
  PersonListPage,
  ProfilePage,
  ReportsListPage,
  ReportsPage,
  SubscriptionCreatePage,
  SubscriptionDetailPage,
  ThingDetailPage,
  ThingListPage,
  ThingMapPage
} from './pages';
import { DASHBOARD_DETAIL_FLAG } from './pages/dashboard-detail/dashboard-detail';
import { Welcome } from './pages/welcome/welcome';

export interface RouteInfo {
  icon?: JSX.Element;
  key?: string;
  page: JSX.Element;
  path: string;
  subText?: string;
  text?: string;
}

const USER_ROLE = [RoleFunction.USER] as const;
const DATA_VIEWER_ROLE = [RoleFunction.DATA_VIEWER] as const;

const ALERT_PERMISSIONS = [RoleFunction.ALERT_VIEWER] as const;
const MEDIA_PERMISSIONS = [RoleFunction.MEDIA_VIEWER] as const;
const SYSTEM_ADMIN_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR] as const;

export const useRoutes = (): RouteInfo[] => {
  const { state } = useAuth();
  const { t } = useTranslation(['common', 'terms', 'track']);
  const flags = useFlags();
  const {
    alert,
    alerts,
    dashboards,
    history,
    people,
    person,
    reports,
    subscription,
    thing,
    things,
  } = useCustomRoutes();
  const { hasAuthorization } = useHasAuthorization(state);

  const alertPermissions = hasAuthorization(ALERT_PERMISSIONS);
  const mediaPermissions = hasAuthorization(MEDIA_PERMISSIONS);
  const systemAdminPermissions = hasAuthorization(SYSTEM_ADMIN_ROLE);

  if (!flags) return [];

  return useMemo<RouteInfo[]>(() => {
    console.log('Calculating routes');

    if (!state || state.status !== AuthStatus.AUTHENTICATED) return [];
    const { account } = state;

    const hasBasicAuthorization = hasAuthorization(USER_ROLE) && hasAuthorization(DATA_VIEWER_ROLE);

    const commonRoutes: RouteInfo[] = [];

    if (flags['portals-profile-feature']) {
      commonRoutes.push({
        page: <ProfilePage />,
        path: '/profile',
      });

      commonRoutes.push({
        page: <ProfilePage />,
        path: `/profile/${subscription}`,
      });

      commonRoutes.push({
        page: <SubscriptionDetailPage isProfilePage />,
        path: `/profile/${subscription}/:subscriptionId`,
      });

      if (flags[PROFILE_PAGE_CREATE_SUBSCRIPTIONS_FLAG as string]) {
        commonRoutes.push({
          page: <SubscriptionCreatePage isProfilePage />,
          path: `/profile/${subscription}/create`,
        });
      }
    }

    if (!hasBasicAuthorization) {
      const noPermissionRoutes: RouteInfo[] = [{
        page: <Welcome />,
        path: '/',
      }];

      return [...noPermissionRoutes, ...commonRoutes];
    }

    const routes: RouteInfo[] = [];
    const overviewDashboardId = String(flags['track-dashboard-sisense-id']) || account.config?.portals?.tracking?.dashboard?.sisenseId;
    routes.push({
      page: flags['track-overview-feature'] && overviewDashboardId
        ? <OverviewPage dashboardId={overviewDashboardId} />
        : <Navigate to={`/map/${things}`} />,
      path: '/',
      text: t('track:page.thing-map.title'),
    });

    if (flags['track-reports-feature']) {
      routes.push({
        page: <ReportsListPage />,
        path: `/${reports}`,
        text: t('track:page.reports.title'),
      });

      routes.push({
        page: <ReportsPage />,
        path: `/${reports}/:dashboardId`,
        text: t('track:page.reports.title'),
      });
    }

    if (systemAdminPermissions && flags[DASHBOARD_DETAIL_FLAG]) {
      routes.push({
        page: <DashboardDetailPage pageType={DashboardType.REPORT} />,
        path: `/${reports}/manage/:dashboardId`,
        text: t('track:page.dashboard-detail.dashboard-details.sub-title'),
      });

      routes.push({
        page: <DashboardDetailPage pageType={DashboardType.DASHBOARD} />,
        path: `/${dashboards}/manage/:dashboardId`,
        text: t('track:page.dashboard-detail.dashboard-details.sub-title'),
      });
    }

    if (flags['track-dashboards-feature']) {
      routes.push({
        page: <DashboardsListPage />,
        path: '/dashboards',
        text: t('track:page.dashboards.title'),
      });

      routes.push({
        page: <DashboardPage />,
        path: '/dashboards/:dashboardId',
        text: t('track:page.dashboards.title'),
      });
    }

    routes.push({
      key: 'thing-map',
      page: <ThingMapPage />,
      path: `/map/${things}`,
      text: t('track:page.thing-map.title'),
    });

    routes.push({
      key: 'thing-map',
      page: <ThingMapPage />,
      path: `/map/${things}/:thingId`,
      text: t('track:page.thing-map.title'),
    });

    routes.push({
      page: <ThingMapPage />, // TODO TP-???: Person map
      path: `/map/${people}`,
      text: t('common:common.labels.map'),
    });

    routes.push({
      page: <ThingListPage />,
      path: `/${things}`,
      text: t('terms:thing', { count: T_MANY }),
    });

    routes.push({
      page: <ThingDetailPage useDataDrivenEventList={Boolean(flags['track-event-card-data-driven-feature'])} />,
      path: `/${thing}/:thingId`,
      text: t('terms:thing', { count: T_ONE }),
    });

    routes.push({
      page: <PersonListPage />,
      path: `/${people}`,
      text: t('terms:person', { count: T_MANY }),
    });

    routes.push({
      page: <PersonDetailPage />,
      path: `/${person}/:personId`,
      text: t('terms:person', { count: T_ONE }),
    });

    if (flags['track-thing-history-feature']) {
      routes.push({
        page: <EventHistoryPage />,
        path: `/${history}`,
        text: t('terms:person', { count: T_ONE }),
      });

      routes.push({
        page: <EventHistoryPage />,
        path: `/${history}/:thingId`,
        text: t('terms:person', { count: T_ONE }),
      });
    }

    if (mediaPermissions) {
      routes.push({
        page: <CameraContentPage data-testid='camera-content' />,
        path: '/camera/:id',
        text: t('track:page.camera-content.title'),
      });
    }

    if (alertPermissions) {
      routes.push({
        page: <AlertListPage />,
        path: `/${alerts}`,
        text: t('common:terms.alert', { count: T_MANY }),
      });

      routes.push({
        page: <AlertDetailPage />,
        path: `/${alert}/:alertId`,
        text: t('common:terms.alert', { count: T_ONE }),
      });
    }

    return [...routes, ...commonRoutes];
  }, [state, t]);
};
